<template>
  <div>
    <div>
      <v-row justify="space-between" align-content="end">
        <v-col class="d-flex flex-row justify-end" cols="12">
          <modalNewCertificate v-model="showModalNewCertificate">
            <v-btn color="info" small black>Adicinar Novo Certificado</v-btn>
          </modalNewCertificate>
        </v-col>
        <v-col>
          <v-tabs>
            <v-tab @click="showActiveCertificates = true">
              Disponíveis ({{ totalActive }})
            </v-tab>
            <v-tab @click="showActiveCertificates = false">
              Vencidos ({{ totalExpired }})
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="listOfCertificate"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:item.fileId="{ item }">
          <v-btn
            style="text-decoration: underline; cursor: pointer"
            :loading="item.tryingToDownload"
            color="info"
            text
            class="ma-0 pa-0"
            @click="downloadCertificate(item)"
          >
            Baixar Certificado
          </v-btn>
        </template>
        <template v-slot:item.password="{ item }">
          {{ item.showPassword ? item.password : '**********' }}
          <v-btn
            class="ml-3"
            text
            icon
            @click="item.showPassword = !item.showPassword"
          >
            <v-icon>
              {{ item.showPassword ? 'visibility' : 'visibility_off' }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.validateAt="{ item }">
          {{ item.validateAt | moment('DD/MM/YYYY') }}
        </template>
        <template slot="no-data">
          <span class="grey--text">
            <v-icon>info</v-icon> Não há certificados.
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import axios from '@plugins/axios'
import { fileMethods } from '@state/helpers'
import { mapGetters } from 'vuex'
import modalNewCertificate from './modalNewCertificate.vue'

export default {
  components: {
    modalNewCertificate,
  },
  data() {
    return {
      showModalNewCertificate: false,
      showActiveCertificates: true,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Arquivo',
          align: 'start',
          sortable: false,
          value: 'fileId',
        },
        {
          text: 'Senha',
          align: 'start',
          sortable: false,
          value: 'password',
        },
        {
          text: 'Vencimento',
          align: 'start',
          sortable: false,
          value: 'validateAt',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      certificatesExpired: 'certificates/certificatesExpired',
      certificatesActive: 'certificates/certificatesActive',
    }),
    listOfCertificate() {
      if (this.showActiveCertificates) {
        return this.certificatesActive
      }
      return this.certificatesExpired
    },
    totalActive() {
      return this.certificatesActive.length
    },
    totalExpired() {
      return this.certificatesExpired.length
    },
  },
  methods: {
    ...fileMethods,
    async downloadCertificate(item) {
      item.tryingToDownload = true
      const response = await axios.get(this.urlToDonload(item.fileId), {
        headers: { 'Content-Type': 'application/x-pkcs12' },
        responseType: 'blob',
      })
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(response.data)
      a.href = url
      a.download = 'Certificado-A1.pfx'
      a.click()
      item.tryingToDownload = false
    },
  },
}
</script>
