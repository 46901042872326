<template>
  <div class="px-10">
    <v-col class="text-subtitle-1" cols="10" style="line-height: 2rem">
      <span class="text-h6">
        <strong>ATENÇÃO!</strong> Ainda não recebemos o seu certificado digital.
      </span>
      <br />
      <span>
        O certificado digital é necessário para que possamos realizar as
        transmissões das suas obrigações contábeis, sem ele não conseguimos
        prestar nossos serviços.
      </span>
      <br />
      <span>
        Você pode contratar o seu eCNPJ tipo A1 com a certificadora de sua
        preferência e precisa ser renovado anualmente.
      </span>
      <br />
      <strong>
        Após a contratação e videoconferência com a Certificadora, favor entrar
        em contato com nossa equipe, ou subir o certificado (com a senha
        cadastrada) diretamente aqui.
      </strong>
      <v-col cols="5" class="ma-0 px-0">
        <certificate-form
          @saved="onSaved()"
          :loading.sync="loading"
        ></certificate-form>
      </v-col>
    </v-col>
  </div>
</template>
<script>
import certificateForm from './CertificateForm.vue'
export default {
  components: {
    certificateForm,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    onSaved() {
      this.$router.go()
    },
  },
}
</script>
