<template>
  <v-container fluid>
    <v-row>
      <v-subheader>Certificado Digital</v-subheader>
      <v-card width="100%" class="k-body">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>

        <v-card-text>
          <newCertificate v-if="!hasCertificateActive" />
          <certificates-list v-if="hasCertificateActive" />
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import newCertificate from '@components/certificate/newCertificate.vue'
import certificatesList from '@components/certificate/certificates.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    newCertificate,
    certificatesList,
  },
  data() {
    return {
      headerTitle: 'Certificado Digital',
      headerDescription: `Você não precisa mais perder tempo procurando seu certificado digital.`,
    }
  },
  computed: {
    ...mapGetters({
      hasCertificateActive: 'certificates/hasCertificateActive',
    }),
  },
  methods: {
    async getCertifigateFromApi() {},
  },
  async mounted() {
    await this.$store.dispatch('certificates/getCertificates')
  },
}
</script>
