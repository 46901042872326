<template>
  <v-dialog v-model="localValue" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <span v-on="on" v-bind="attrs">
        <slot></slot>
      </span>
    </template>
    <v-card class="pa-2" v-if="localValue">
      <v-card-title class="pa-2">Novo Certificado </v-card-title>
      <v-card-text>
        <certificateForm :loading.sync="loading" @saved="closeModal()">
          <template v-slot:actions>
            <v-col class="d-flex flex-row mt-1 px-0">
              <v-btn color="success" type="submit" :loading="loading">
                salvar
              </v-btn>
              <v-btn
                class="ml-2"
                color="#1565C0"
                outlined
                @click="closeModal()"
              >
                Fechar
              </v-btn>
            </v-col>
          </template>
        </certificateForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import certificateForm from './CertificateForm.vue'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    certificateForm,
  },
  data() {
    return {
      viewCertificate: true,
      loading: false,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    closeModal() {
      this.localValue = false
    },
  },
}
</script>
