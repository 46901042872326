<template>
  <v-form ref="form" @submit.prevent="save()">
    <v-file-input
      :rules="rules.file"
      accept=".pfx, application/x-pkcs12"
      truncate-length="15"
      label="Certificado"
      v-model="file"
      append-icon="attach_file"
      prepend-icon
    />
    <v-text-field
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
      :append-icon="showPassword ? 'visibility' : 'visibility_off'"
      :rules="rules.password"
      v-model="password"
      label="Senha"
    />
    <slot name="actions">
      <v-btn :loading="localLoading" color="success" type="submit">
        salvar
      </v-btn>
    </slot>
  </v-form>
</template>
<script>
import { fileMethods } from '@state/helpers'
export default {
  props: ['loading'],
  data() {
    return {
      passwordIsInValid: false,
      showPassword: false,
      password: '',
      file: null,
      rules: {
        password: [
          (value) => !!value || 'Por favor, preencher a senha.',
          () => !this.passwordIsInValid || 'A senha está errada',
        ],
        file: [
          (value) => {
            return (
              value?.type == 'application/x-pkcs12' ||
              "O certificado deve ser do tipo'.pfx'."
            )
          },
        ],
      },
    }
  },
  computed: {
    localLoading: {
      get() {
        return this.loading
      },
      set(value) {
        this.$emit('update:loading', value)
      },
    },
  },
  methods: {
    ...fileMethods,
    async createOrUpdate() {
      let data = {
        password: this.password,
        file_certificate: null,
      }
      data.file_certificate = await this.fileToBase64(this.file)
      await this.$store.dispatch('certificates/createCertificate', data)
    },
    async save() {
      this.passwordIsInValid = false
      if (!this.$refs.form.validate()) return
      try {
        this.localLoading = true
        await this.createOrUpdate()
        this.localLoading = false
        this.$emit('saved', true)
      } catch (error) {
        this.localLoading = false
        this.passwordIsInValid = true
        this.$refs.form.validate()
      }
    },
  },
}
</script>
